import { clinicGetAllInfo } from 'api/firebase';
import { useCallback, useEffect, useState } from 'react';
import { Clinic } from './clinic';

export function useClinicInfo(clinicId: string | undefined | null) {
  const [clinicInfo, setClinicInfo] = useState<{
    clinic: Clinic;
    altLink?: string;
  } | null>(null);
  const refreshClinicInfo = useCallback(async () => {
    setClinicInfo(null);

    if (!clinicId) {
      return;
    }
    try {
      const { data: clinicInfo } = await clinicGetAllInfo(clinicId);

      setClinicInfo(clinicInfo);
    } catch (e) {
      setClinicInfo(null);
      throw e;
    }
  }, [clinicId]);
  useEffect(() => {
    refreshClinicInfo();
  }, [clinicId, refreshClinicInfo]);

  return { clinicInfo, refreshClinicInfo };
}
