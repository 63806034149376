import { htmlToPDF } from 'api/firebase';
import React, { Dispatch } from 'react';
import { printSliceActions } from './print-slice';

export const DEBUG_PDF_GENERATION = false;
export async function printPageSlow(
  printableDomElementRef: React.ReactInstance | null,
  dispatch?: Dispatch<any>
) {
  if (printableDomElementRef === null) return null;

  const [root, head] = createPDFHTML(printableDomElementRef as Element);
  dispatch?.(printSliceActions.setProgressionStatus('Generating PDF'));

  console.log('Exporting', root.outerHTML);
  if (DEBUG_PDF_GENERATION) {
    debugPrintOutput(root, head);

    /**
     * Enable to only preview the HTML
     */
    dispatch?.(printSliceActions.setFinished());
    return '';
  }
  const result = await htmlToPDF(root.outerHTML);

  const arr = result.data as string;
  const buffer = Uint8Array.from(JSON.parse(arr));
  const blob = new Blob([buffer], { type: 'application/pdf' });

  dispatch?.(printSliceActions.setFinished());

  return downloadBlob('crx-output.pdf', blob);
  const url = URL.createObjectURL(blob);

  // The new window can be blocked by the browser
  window.open(url);

  return url;
}
/**
 * Create a new window the HTML to print
 * @param root
 * @param head
 * @returns
 */
function debugPrintOutput(root: Element, head: Element) {
  /**
   * Debug code
   */
  const w = window.open();
  if (!w) return;
  w.document.head.innerHTML = head.innerHTML;
  w.document.write(root.outerHTML);
  return;
  /**
   * End debug code
   */
}

/**
 * Copy HTML from the `ref` element to a virtual element
 * @param ref
 * @returns
 */
export function createPDFHTML(ref: Element) {
  const newEl = ref.cloneNode(true);
  const root = document.createElement('html');
  const head = document.createElement('head');
  const body = document.createElement('body');

  // extractStyles(root);

  /**
   * Copy everything from the current page into the PDF html
   *
   * This is a simple way to make sure that all the right CSS files and rules are being loaded
   *
   * This also replaces relative CSS URLs with global URLs.
   */
  head.innerHTML = document.head.innerHTML.replaceAll(
    '<link href="/static/css',
    `<link href="${location.origin}/static/css`
  );

  root.appendChild(head);
  root.appendChild(body);
  // body.appendChild(newEl);
  root.appendChild(newEl);

  return [root, head, body];
}

/**
 *
 * @deprecated This is not used anymore because Firefox does not currently support the `@page { @top-right {} }` CSS selector
 * @param rootEl
 */
// eslint-disable-next-line
function extractStyles(rootEl: Element) {
  const styleNodes = document.querySelectorAll(
    "style, link[rel~='stylesheet'], link[as='style']"
  );
  for (let i = 0; i < styleNodes.length; i++) {
    const node = styleNodes[i];
    const nodeType = node.tagName.toLowerCase();
    let newEl: Element | null = null;

    switch (nodeType) {
      case 'style':
        newEl = processStyleNode(node as HTMLStyleElement);
        break;
      case 'link':
        newEl = processLinkNode(node as HTMLLinkElement);
        break;
      default:
        throw new Error(`Unexpected element type '${nodeType}' at index ${i}`);
    }

    /**
     * The element failed to process
     * Continue to the next element
     */
    if (newEl === null) continue;
    rootEl.appendChild(newEl);
  }
}

function processStyleNode(node: HTMLStyleElement) {
  if (!node.sheet) return null;

  const sheet = node.sheet;
  let cssStyles = '';

  // Try copying the styles from the current page to the exported HTML
  try {
    const newEl = node.cloneNode() as HTMLStyleElement;
    const rules = sheet.cssRules;

    for (let j = 0; j < rules.length; j++) {
      cssStyles += rules[j].cssText + '\n';
    }

    newEl.innerHTML = cssStyles;

    return newEl;
  } catch (e) {
    return null;
  }
}

function processLinkNode(node: HTMLLinkElement) {
  const href = node.getAttribute('href');
  if (!href) return null;
  const newNode = node.cloneNode() as HTMLLinkElement;
  newNode.href = new URL(newNode.href, document.baseURI).href;
  return newNode;
}

/**
 * Downloads a blob in the background
 * Returns an object URL. The URL should be revoked with `URL.revokeObjectURL` to avoid a memory leak.
 *
 * @param name
 * @param blob
 * @returns
 */

export function downloadBlob(name: string, blob: Blob) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', name);
  a.click();

  return url;
}
