import { Flex, Skeleton } from 'antd';
import { createRepo } from 'api/firebase';
import { listClinicians } from 'api/firebase/firebase-api';
import { useAppSelector } from 'app/rootReducer';
import { PrintableRootPage } from 'components/page/page-2';
import { Clinician } from 'documents/clinician';
import { Collection } from 'documents/document';
import { User } from 'features/auth/authSlice';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { PatientTable } from './components/user-list/user-list-patient';
import {
  BASE_USER_ADD_QUESTIONS,
  FormQuestion
} from './components/user-add-modal-component';
import { ClinicianTable } from './components/user-list/user-list-clinician';
import { useClinicInfo } from '../clinic-info-edit/useClinicInfo';

export default function ClinicUserManagement() {
  const { user, isUserDataLoading } = useAppSelector(s => s.auth);
  const { clinicId, error } = useResolveCurrentClinicInfo(
    user,
    isUserDataLoading
  );
  const patientUserAddQuestions = useResolvePatientUserAddQuestions(clinicId);

  return (
    <PrintableRootPage title="User management">
      <Flex vertical gap={80}>
        {clinicId && (
          <>
            <div>
              <PatientTable
                clinicId={clinicId}
                patientUserAddQuestions={patientUserAddQuestions}
              />
            </div>
            <div>
              <ClinicianTable clinicId={clinicId} />
            </div>
          </>
        )}
        {!clinicId && !error && <Skeleton active />}
        {error && (
          <ErrorDisplay
            title="Something went wrong when loading clinician information"
            description={`Please report this error to contact@concussionrx.com`}
            error={error}
          />
        )}
      </Flex>
    </PrintableRootPage>
  );
}

/**
 * Resolve the current clinic from the clinician credentials
 *
 * TODO: Resolve this through a context and higher in the app
 * @param user
 * @param isUserDataLoading
 * @returns
 */
function useResolveCurrentClinicInfo(
  user: User | null,
  isUserDataLoading: boolean
) {
  const [clinicId, setClinicId] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    async function setup(user: User | null, isUserDataLoading: boolean) {
      if (isUserDataLoading) return;
      if (!user) throw new Error(`You must be logged in`);

      const clinicianRepo = createRepo<Clinician>(Collection.Clinicians);
      const clinicianInfo = await clinicianRepo.find(user.uid);
      if (!clinicianInfo)
        throw new Error(`Clinician (id=${user.uid}) not found`);
      clinicianInfo?.clinics[0];
      setClinicId(clinicianInfo?.clinics[0]);
    }
    setup(user, isUserDataLoading)
      .then(() => {
        setError(null);
      })
      .catch(error => {
        setError(resolveError(error));
      });
  }, [user, isUserDataLoading]);

  const clinicInfo = useClinicInfo(clinicId);
  return { clinicId, clinicInfo, error };
}

/**
 * Hook to generate the questions for patient add and edit actions
 * @param clinicId
 * @returns
 */
function useResolvePatientUserAddQuestions(clinicId: string | null) {
  const [patientUserAddQuestions, setPatientUserAddQuestions] = useState<
    FormQuestion[]
  >([
    ...BASE_USER_ADD_QUESTIONS,
    {
      type: 'select',
      name: 'clinicianId',
      label: 'Clinician',
      rules: [{ required: true }, { type: 'enum', enum: [] }],
      options: [],

      // Disable the select until clinician data is ready to list
      loading: true,
      disabled: true
    }
  ]);
  useEffect(() => {
    async function getClinicians(clinicId: string) {
      const result = await listClinicians({
        clinicId
      });

      const clinicians = [
        ...result.data.inClinic,
        ...result.data.pendingInClinic
      ];
      const options = uniqBy(
        clinicians.map(_ => ({
          label: `${_.displayName} - ${_.id}`,
          value: _.id
        })),
        _ => _.value
      );
      // Set the clinicians list to be available in the patients add-user options
      setPatientUserAddQuestions([
        ...BASE_USER_ADD_QUESTIONS,
        {
          type: 'select',
          name: 'clinicianId',
          label: 'Clinician',
          rules: [
            { required: true },
            { type: 'enum', enum: options.map(_ => _.value) }
          ],
          options
        }
      ]);
    }

    if (!clinicId) return;
    getClinicians(clinicId);
  }, [clinicId]);
  return patientUserAddQuestions;
}
