import { Patient } from 'documents/patient';
import { z } from 'zod';
import { FormQuestion } from './user-add-modal-component';
import { ActionTagSettings } from './action-tag/action-tag-component';
import { ColumnsType } from 'antd/lib/table';

export const tableUserDataSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email()
});
type TableUserData = z.infer<typeof tableUserDataSchema>;

export type APIData = Pick<Patient, 'displayName' | 'email' | 'id'>;
export type TableRow<T> = TableUserData & {
  invitePending: boolean;
  key: string;
  raw: T;
};
export interface ListUserReturnResult<T extends APIData> {
  inClinic: T[];
  pendingInClinic: T[];
}
export interface UserListProps<T extends APIData> {
  clinicId: string;
  api: (options: {
    clinicId: string;
    lastUserId?: string;
    lastPendingUserId?: string;
  }) => Promise<{ data: ListUserReturnResult<T> }>;
  apiAddUser: (
    userData: TableUserData,
    clinicId: string
  ) => Promise<void | any>;
  actions?: ActionTagSettings<T>[];
  typeUnit: string;
  userAddQuestions: FormQuestion[];
  extraColumns?: ColumnsType<TableRow<T>>;
}
