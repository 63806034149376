import {
  addPatient,
  clinicActionAllowPatientAssessments,
  clinicActionDisablePatientAssessments,
  clinicActionEditPatientDetails,
  clinicActionResendInvitation,
  listPatients
} from 'api/firebase/firebase-api';
import { Patient } from 'documents/patient';
import {
  ActionState,
  ActionTagSettings
} from '../action-tag/action-tag-component';
import { ActionTagEditDetails } from '../action-tag/action-tag-edit-details';
import { UserListProps } from '../table-types';
import { FormQuestion } from '../user-add-modal-component';
import UserList from './user-list-component';

export function PatientTable(props: {
  clinicId: string;
  /**
   * TODO: Move this attribute to `PatientTable`
   *
   * @deprecated
   */
  patientUserAddQuestions: FormQuestion[];
}) {
  const actions: ActionTagSettings<Patient>[] = [
    {
      name: p =>
        p.raw.canStartAssessments ? 'Activated' : 'Activate Assessment',
      onClick: async p => {
        if (p.raw.id === undefined)
          throw new Error('ID is undefined. Please try reloading the page');
        return clinicActionAllowPatientAssessments(p.raw.id);
      },
      shouldShow: p => !p.invitePending,
      initialState: p =>
        p.raw.canStartAssessments ? ActionState.SUCCESS : ActionState.NEUTRAL,
      toolTip: p =>
        p.raw.canStartAssessments
          ? `This patient can start new assessments`
          : `Click to allow this patient to start new assessments`
    },
    {
      name: p =>
        !p.raw.canStartAssessments
          ? '[NOT SHOWN IN PRODUCTION] Disabled'
          : '[NOT SHOWN IN PRODUCTION] Disable Assessment',
      onClick: async p => {
        if (p.raw.id === undefined)
          throw new Error('ID is undefined. Please try reloading the page');
        return clinicActionDisablePatientAssessments(p.raw.id);
      },
      shouldShow: p =>
        ['local', 'development', 'qa'].includes(
          process.env.REACT_APP_CONCUSSIONRX_DEPLOYMENT as string
        ) && !p.invitePending,
      initialState: p =>
        !p.raw.canStartAssessments ? ActionState.SUCCESS : ActionState.NEUTRAL,
      toolTip: p =>
        p.raw.canStartAssessments
          ? `This patient cannot start new assessments`
          : `Click to disable this patient from starting new assessments`
    },
    {
      name: 'Resend invitation',
      shouldShow: p => p.invitePending,
      initialState: () => ActionState.NEUTRAL,
      onClick: p => {
        if (!p.raw.id)
          throw new Error('ID is undefined. Please try reloading the page');
        return clinicActionResendInvitation(p.raw.id);
      }
    },
    {
      name: 'Edit',
      initialState: () => ActionState.NEUTRAL,
      render: _props => (
        <ActionTagEditDetails
          {..._props}
          userAddQuestions={props.patientUserAddQuestions}
          initialValues={{
            firstName: _props.record.firstName,
            lastName: _props.record.lastName,
            email: _props.record.email,
            clinicianId: (_props.record.raw as any).assignedClinicians
              .map((_: any) => `${_.id}`)
              .join(', ')
          }}
        />
      ),
      onClick: p => {
        if (!p.raw.id) {
          throw new Error('ID is undefined. Please try reloading the page');
        }
        console.log(p);
        return clinicActionEditPatientDetails(
          p.raw.id,
          p.firstName,
          p.lastName,
          p.email,
          (p as any).clinicianId,
          props.clinicId
        );
      },
      onSuccess: {
        blockOnDone: false,
        timeout: 1000
      }
    }
  ];
  const api: UserListProps<Patient>['api'] = ({
    clinicId,
    lastUserId,
    lastPendingUserId
  }) =>
    listPatients({
      clinicId,
      usersLimit: 10,
      pendingUsersLimit: 10,
      ...(lastPendingUserId && {
        pendingUsersLastDocId: lastPendingUserId
      }),
      ...(lastUserId && { usersLastDocId: lastUserId })
    });
  const apiAddUser: UserListProps<Patient>['apiAddUser'] = (user, clinicId) =>
    addPatient({ ...(user as any), clinicId });

  const extraColumns: UserListProps<Patient>['extraColumns'] = [
    {
      title: 'Clinician',
      dataIndex: 'clinician',
      render: (_value, record) => {
        const rawRecord = record.raw as any;
        if (rawRecord.assignedClinicians === undefined) {
          console.warn(
            `[extraColumns - clinician] Missing assignedClinicians property`
          );
          return '';
        }
        return rawRecord.assignedClinicians.map((_: any) => _.name).join(', ');
      }
    }
  ];
  return (
    <UserList<Patient>
      typeUnit="Patient"
      apiAddUser={apiAddUser}
      api={api}
      clinicId={props.clinicId}
      extraColumns={extraColumns}
      actions={actions}
      userAddQuestions={props.patientUserAddQuestions}
    />
  );
}
